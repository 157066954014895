'use client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { useGetCurrentUserInfoRequest } from '@/src/components/Auth/hooks/useGetCurrentUserInfoRequest';
import { useHasAuthenticatedLayoutCookie } from '@/src/components/Auth/hooks/useHasAuthenticatedLayoutCookie';
import { LOGOUT_EVENT_NAME } from '@/src/components/Auth/hooks/useLogoutRequest';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { setUser, fetchFeatureFlips } from '@/src/store/reducers/main';
import { useReduxDispatch } from '@/src/store/store';
export const UserManager = () => {
  const dispatch = useReduxDispatch();
  const router = useRouter();
  const tenantAlias = useTenantAlias();
  const {
    setHasAuthenticatedLayoutCookie,
    removeHasAuthenticatedLayoutCookie
  } = useHasAuthenticatedLayoutCookie();
  const {
    isSuccess: isUserSuccess,
    data: userInfo
  } = useGetCurrentUserInfoRequest();
  const {
    isSuccess: isTenantConfigSuccess,
    data: tenantConfig
  } = useQuery(spaQueries.app.tenantConfig(tenantAlias));

  // Fetch feature flips
  useEffect(() => {
    dispatch(fetchFeatureFlips({
      tenantAlias
    }));
  }, [dispatch, tenantAlias]);

  // Handle logout event
  useEffect(() => {
    const handleLogout = (event: StorageEvent) => {
      if (event.key === LOGOUT_EVENT_NAME) {
        dispatch(setUser(null));
        Sentry.setUser(null);
        router.push('/login');
      }
    };
    window.addEventListener('storage', handleLogout);
    return () => {
      window.removeEventListener('storage', handleLogout);
    };
  }, [dispatch, router]);

  // Set Sentry user
  useEffect(() => {
    if (isUserSuccess) {
      Sentry.setUser(userInfo?.user ? {
        id: String(userInfo.user.id)
      } : null);
    }
  }, [userInfo?.user, isUserSuccess]);

  // Validate user is a supplier for this tenant
  useEffect(() => {
    if (isUserSuccess && isTenantConfigSuccess && userInfo && tenantConfig && userInfo.user) {
      const {
        user
      } = userInfo;
      if (user.company?.isSupplier !== true || user.tenant !== tenantConfig.tenantID) {
        // logout if the SPA user is not a supplier
        router.push('/logout');
      }
    }
  }, [isUserSuccess, isTenantConfigSuccess, userInfo, tenantConfig, router]);

  // Handle authentication cookie
  useEffect(() => {
    if (isUserSuccess && userInfo) {
      const {
        user
      } = userInfo;
      if (user) {
        setHasAuthenticatedLayoutCookie();
      } else {
        removeHasAuthenticatedLayoutCookie();
      }
    }
  }, [isUserSuccess, userInfo, setHasAuthenticatedLayoutCookie, removeHasAuthenticatedLayoutCookie]);

  // Set user in Redux store
  useEffect(() => {
    if (isUserSuccess && userInfo) {
      dispatch(setUser(userInfo.user));
    }
  }, [isUserSuccess, userInfo, dispatch]);
  return null;
};