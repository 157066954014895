import { isDevEnv, isTestEnv } from '@qb/frontend/utils/env.utils';
import { getHostFromWindow } from '@qb/frontend/utils/hosts/getHostFromWindow';
import { API_HOST } from '@qb/web/WebConstants';

const API_PREFIX_WEB = 'WEB';
const API_PREFIX_REST = 'REST';
const API_PREFIX_SPS = 'SPS';
const API_PREFIX_SPA = 'SPA';
const API_PREFIX_SPW = 'SPW';
const API_PREFIX_INTERNAL_ADMIN = 'internalAdmin';
export const API_PREFIXES = {
  [API_PREFIX_WEB]: '/api/web',
  [API_PREFIX_REST]: '/api/rest',
  [API_PREFIX_SPS]: '/api/sps',
  [API_PREFIX_SPA]: '/api/spa',
  [API_PREFIX_SPW]: '/api/widget',
  [API_PREFIX_INTERNAL_ADMIN]: '/internalAdmin',
} as const;

type APIPrefix = keyof typeof API_PREFIXES;
/**
 * Get the API URL from host
 */
export const getAPIUrlFromHost = (host: string) => {
  // Allows overriding the API host for development or test envs, for example with staging api or test backend api
  if ((isDevEnv || isTestEnv) && process.env.API_HOST_OVERRIDE) {
    return process.env.API_HOST_OVERRIDE;
  }
  const baseDomain = host.split('.').slice(-2).join('.');
  const cleanBaseDomain = baseDomain.split(':')[0];
  const port = isDevEnv ? ':1337' : '';

  return `https://api.${cleanBaseDomain}${port}`;
};

/**
 * Get the API URL for the given tenant alias and API type.
 */
const getApiURL = (apiType: APIPrefix, tenantAlias: string, host: string) => {
  if (!host) {
    throw new Error('Host is required to get the API prefix');
  }

  const apiHost = getAPIUrlFromHost(host);

  let prefix = API_PREFIXES[apiType];
  if (apiType === API_PREFIX_SPS || apiType === API_PREFIX_SPA) {
    prefix += `/${tenantAlias}`;
  }

  return apiHost + prefix;
};

// Gets the full URL for the web API (tenant specific) using the tenant alias and host
export const getWebApiURLForTenant = (tenantAlias: string, host: string) => {
  return getApiURL(API_PREFIX_WEB, tenantAlias, host);
};

// Gets the full URL for the SPA API (tenant specific) using the tenant alias and host
export const getSPAApiURLForTenant = (tenantAlias: string, host: string) =>
  getApiURL(API_PREFIX_SPA, tenantAlias, host);

// Gets the full URL for the REST API (tenant specific) using the tenant alias and host
export const getRestApiURLforTenant = (tenantAlias: string, host: string) => {
  return getApiURL(API_PREFIX_REST, tenantAlias, host);
};

// Gets the full URL for the SPS API (tenant specific) using the tenant alias and host
export const getSPSApiURLForTenant = (tenantAlias: string, host: string) => {
  return getApiURL(API_PREFIX_SPS, tenantAlias, host);
};

// Gets the full URL for the SPW API (tenant specific) using the tenant alias and host
export const getSPWApiURLForTenant = () => {
  const tenantAlias = new URLSearchParams(window.location.search).get(
    'tenantAlias',
  );

  if (!tenantAlias) {
    throw new Error(
      'Unable to read tenantAlias from url for getSPWApiURLForTenant',
    );
  }

  const host = getHostFromWindow();

  return getApiURL(API_PREFIX_SPW, tenantAlias, host);
};

// Gets the full URL for the web API using the env variable (not tenant specific).
export const getWebApiURL = () => {
  return API_HOST + API_PREFIXES.WEB;
};

// Gets the base URL for the web API using the env variable (not tenant specific).
export const getWebApiBaseURL = () => {
  return API_HOST;
};

// Gets the full URL for the rest API using the env variable (not tenant specific)
export const getRestApiURL = () => {
  return API_HOST + API_PREFIXES.REST;
};

// Gets the full URL for the internal admin API using the env variable (not tenant specific)
export const getInternalAdminApiPrefix = () => {
  return API_HOST + API_PREFIXES.internalAdmin;
};
