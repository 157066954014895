import { socketErrorHandler } from './RequestPromise';
import { SocketGetRequestWithData, SocketPostRequest } from './SocketRequest';

export const getSocketRequestPromise = async <Request, Response>(
  url: string,
  payload: Request | null = null,
  errorMessage?: string,
): Promise<Response> => {
  return new Promise<Response>((resolve, reject) =>
    SocketGetRequestWithData(url, payload, resolve, reject),
  ).catch((e) => socketErrorHandler(e, errorMessage));
};

export const postSocketRequestPromise = async <Request, Response>(
  url: string,
  payload: Request | null = null,
  errorMessage?: string,
): Promise<Response> => {
  return new Promise<Response>((resolve, reject) =>
    SocketPostRequest(url, payload, resolve, reject),
  ).catch((e) => socketErrorHandler(e, errorMessage));
};

export const appendParamsToUrl = (
  url: string,
  params: Record<string, string | undefined>,
) => {
  const [uri, paramString] = url.split('?');

  const urlSearchParams = new URLSearchParams(paramString);

  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      urlSearchParams.set(key, value);
    }
  });

  return [uri, urlSearchParams.toString()].join('?');
};
