import { useQuery } from '@tanstack/react-query';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';

export const useGetCurrentUserInfoRequest = () => {
  const tenantAlias = useTenantAlias();

  return useQuery({
    ...spaQueries.user.getCurrentUserInfo(tenantAlias),
  });
};
