import {
  getRestApiURLforTenant,
  getSPAApiURLForTenant,
} from '@qb/frontend/utils/hosts/apiHost';
import { getHostFromWindow } from '@qb/frontend/utils/hosts/getHostFromWindow';
import { AppDisplayPayload } from '@/shared/types/controllers/AppControllerTypes';
import {
  QuoteFeeCreateQuoteFeePayload,
  QuoteFeeCreateQuoteFeeResponse,
  QuoteFeeUpdateQuoteFeePayload,
  QuoteFeeUpdateQuoteFeeResponse,
} from '@/shared/types/controllers/QuoteFeeControllerTypes';
import { SupplierPriceUpdateSupplierPricePayload } from '@/shared/types/controllers/SupplierPriceControllerTypes';
import {
  ChangePasswordPayload,
  DemoteFromAdminPayload,
  DisableUserPayload,
  EnableUserPayload,
  GetProfileImageResponse,
  PromoteToAdminPayload,
  SettingsNotificationsDataResponse,
  UpdateMuteSMSRequest,
  UserAllAssigneesResponse,
  UserUpdateUserPayload,
  UserUpdateUserResponse,
} from '@/shared/types/controllers/UserControllerTypes';
import { UserInviteTokenFindOneUserInviteTokenResponse } from '@/shared/types/controllers/UserInviteTokenControllerTypes';
import {
  AppPublicSPAConfig,
  AppSPAConfig,
  AppUpdateShippingCutoff,
} from '@/shared/types/controllers/spa/SPAAppControllerTypes';
import {
  ForgotPasswordPayload,
  LoginPayload,
  LoginResponse,
  SignupPayload,
} from '@/shared/types/controllers/spa/SPAAuthControllerTypes';
import {
  CRMGetUserHeaderResponse,
  CRMGetUserStatisticsResponse,
  CRMGetUserActivityResponse,
  CRMGetCompanyListPayload,
  CRMGetCompanyListResponse,
  CRMGetCompanyHeaderResponse,
  CRMCompanyStatisticsResponse,
  CRMGetCompanyActivityResponse,
  CRMGetCompanyQuotesResponse,
  CRMGetCompanyOrdersResponse,
  CRMGetCompanyDetailsResponse,
  CRMGetContactHeaderResponse,
  CRMGetContactStatisticsResponse,
  CRMGetContactActivityResponse,
  CRMGetCompanyActivityPayload,
  CRMGetCompanyQuotesPayload,
  CRMGetCompanyOrdersPayload,
  CRMGetContactActivityPayload,
  CRMGetUserActivityPayload,
  CRMGetUserQuotesPayload,
  CRMGetUserQuotesResponse,
  CRMGetContactQuotesPayload,
  CRMGetContactQuotesResponse,
  CRMGetUserOrdersPayload,
  CRMGetUserOrdersResponse,
  CRMGetUserStatisticsPayload,
  CRMGetUserHeaderPayload,
  CRMGetCompanyHeaderPayload,
  CRMCompanyStatisticsPayload,
  CRMGetCompanyDetailsPayload,
  CRMGetContactHeaderPayload,
  CRMGetContactStatisticsPayload,
} from '@/shared/types/controllers/spa/SPACRMControllerTypes';
import {
  GetCertificateListPayload,
  GetCertificateListResponse,
  SetCertificateApprovalPayload,
} from '@/shared/types/controllers/spa/SPACertificateControllerTypes';
import {
  CompanyUpdateIsInitialConversationNotifyPayload,
  CompanyUpdateIsInitialPONotifyPayload,
  CompanyUpdateIsInitialQuoteNotifyPayload,
  CompanySettingsUsersDataResponse,
  CompanyInfoResponse,
  CompanyLogoResponse,
  CompanySettingsIntegrationsDataResponse,
  CompanyAllUsersAsOptions,
  CompanySettingsSupplierDataResponse,
  CompanySettingsMarketplaceDataResponse,
  UpdateProfitMarginsPayload,
  AddToPaymentTermNamesPayload,
  GetManufacturerHasPartCategoryAndProductLineResponse,
  GetManufacturerHasPartCategoryAndProductLinePayload,
} from '@/shared/types/controllers/spa/SPACompanyControllerTypes';
import {
  ContactCreateForCompanyPayload,
  ContactCreateForCompanyResponse,
  ContactCreatePayload,
  ContactCreateResponse,
  ContactFindOneByEmailPayload,
  ContactFindOneByEmailResponse,
  ContactFindOneResponse,
  ContactListPayload,
  ContactListResponse,
  ContactUpdatePayload,
} from '@/shared/types/controllers/spa/SPAContactControllerTypes';
import {
  ConversationCreateAppConversationPayload,
  ConversationCreateAppConversationResponse,
  ConversationDisplayDataResponse,
  ConversationHasUnreadConversationResponse,
} from '@/shared/types/controllers/spa/SPAConversationControllerTypes';
import {
  CouponCodeCreateCouponCodePayload,
  CouponCodeGetResponse,
  CouponCodeListCouponCodesResponse,
  CouponCodeUpdateCouponCodePayload,
} from '@/shared/types/controllers/spa/SPACouponCodeControllerTypes';
import {
  CreateCustomPartPayload,
  CreateCustomPartResponse,
} from '@/shared/types/controllers/spa/SPACustomPartControllerTypes';
import {
  CreateCustomerPricePayload,
  GetCustomerPriceDashboardPayload,
  GetCustomerPriceDashboardResponse,
  GetCustomerPriceResponse,
  GetPricingBreakdownByManufacturerPayload,
  GetPricingBreakdownByManufacturerResponse,
  UpdateCustomerPricePayload,
} from '@/shared/types/controllers/spa/SPACustomerPriceControllerTypes';
import { SupplierDashboardDataResponse } from '@/shared/types/controllers/spa/SPADashboardControllerTypes';
import {
  GetErpShippingMethodsResponse,
  GetOctopartIntegrationResponse,
  UpsertOctopartIntegrationPayload,
  GetGoogleMerchantIntegrationResponse,
  UpsertGoogleMerchantIntegrationPayload,
  GetPartsDirectIntegrationResponse,
  UpsertPartsDirectIntegrationPayload,
} from '@/shared/types/controllers/spa/SPAIntegrationControllerTypes';
import { NavbarGetDataResponse } from '@/shared/types/controllers/spa/SPANavbarControllerTypes';
import { NotificationPolicyUpsertPayload } from '@/shared/types/controllers/spa/SPANotificationPolicyControllerTypes';
import {
  DisplayCancelledItemsDataResponse,
  OrderCustomerDetailsResponse,
  OrderDenyPayload,
  OrderGetIntegrationExceptionsResponse,
  OrderGetOrderResponse,
  OrderListPayload,
  OrderListResponse,
  OrderPartialShipPayload,
  OrderRecentBuyerOrdersPayload,
  OrderRecentBuyerOrdersResponse,
  OrderUpdateShippingMethodPayload,
  OrderUpdateSupplierNotesPayload,
  SetManualSupplierIntegrationPayload,
  SetTaxAndShippingOverridePayload,
  UpdateOrderAddressPayload,
} from '@/shared/types/controllers/spa/SPAOrderControllerTypes';
import { OrderItemUpdatePayload } from '@/shared/types/controllers/spa/SPAOrderItemControllerTypes';
import {
  PartCategoryAutocompletePayload,
  PartCategoryAutocompleteResponse,
} from '@/shared/types/controllers/spa/SPAPartCategoryControllerTypes';
import { PartPublicDataResponse } from '@/shared/types/controllers/spa/SPAPartControllerTypes';
import {
  PartInventoryUpdateCountPayload,
  PartInventoryUpdateCountResponse,
} from '@/shared/types/controllers/spa/SPAPartInventoryControllerTypes';
import {
  CreatePartRequestPayload,
  GetPartRequestSuggestedPricingResponse,
  PartRequestUpdatePriceAndQtyPayload,
  PartRequestUpdateRankingPayload,
} from '@/shared/types/controllers/spa/SPAPartRequestControllerTypes';
import {
  CreatePaymentTermPayload,
  GetPaymentTermsDashboardPayload,
  GetPaymentTermsDashboardResponse,
  GetPaymentTermsRequestParams,
  GetPaymentTermsResponse,
  UpdatePaymentTermPayload,
} from '@/shared/types/controllers/spa/SPAPaymentTermControllerTypes';
import {
  GetProductLinesByManufacturerRequest,
  GetProductLinesByManufacturerResponse,
  ProductLineAutocompletePayload,
  ProductLineAutocompleteResponse,
} from '@/shared/types/controllers/spa/SPAProductLineControllerTypes';
import {
  CreateForContactPayload,
  QuoteDisplayDataResponse,
  QuoteListDataPayload,
  QuoteListDataResponse,
  QuoteNoBidPayload,
  QuoteRecentBuyerQuotesPayload,
  QuoteRecentBuyerQuotesResponse,
  QuoteRecentContactQuotesPayload,
  QuoteRecentContactQuotesResponse,
  QuoteUpdateAssigneePayload,
  QuoteUpdateNotesSupplierPayload,
  QuoteUpdateQuoteNotePayload,
  QuoteUpdateValidityDurationPayload,
} from '@/shared/types/controllers/spa/SPAQuoteControllerTypes';
import {
  UpsertGlobalSPPartPageSettingPayload,
  GetSPPartPageSettingByManufacturerResponse,
  GetGlobalSPPartPageSettingResponse,
  UpsertSPPartPageSettingByManufacturerPayload,
} from '@/shared/types/controllers/spa/SPASPPartPageSettingControllerTypes';
import { SetSalesRepPayload } from '@/shared/types/controllers/spa/SPASalesRepControllerTypes';
import {
  GetGeneralSalesTerritoriesResponse,
  GetSalesTerritoriesForManufacturerPayload,
  GetSalesTerritoriesForManufacturerResponse,
  SalesTerritorySetGeneralSalesTerritoryBatchPayload,
  SalesTerritorySetSalesTerritoryBatchPayload,
} from '@/shared/types/controllers/spa/SPASalesTerritoryControllerTypes';
import {
  CompanyByUserAutocompletePayload,
  CompanyByUserAutocompleteResponse,
  ContactAutocompletePayload,
  ContactAutocompleteResponse,
  ManufacturerAutocompletePayload,
  ManufacturerAutocompleteResponse,
  PartAutocompletePayload,
  PartAutocompleteResponse,
  QuoteAutocompletePayload,
  QuoteAutocompleteResponse,
  OrderAutocompletePayload,
  OrderAutocompleteResponse,
} from '@/shared/types/controllers/spa/SPASearchControllerTypes';
import { GetSerpSettingsResponse } from '@/shared/types/controllers/spa/SPASerpSettingsControllerTypes';
import {
  UpsertSupplierManufacturerInfoPayload,
  GetSupplierManufacturerInfoByManufacturerPayload,
  GetSupplierManufacturerByManufacturerInfoResponse,
} from '@/shared/types/controllers/spa/SPASupplierManufacturerInfoControllerTypes';
import { UploadPartCategoryImagePayload } from '@/shared/types/controllers/spa/SPASupplierPartCategoryDataControllerTypes';
import {
  GetSupplierPriceDashboardPayload,
  GetSupplierPriceDashboardResponse,
  UploadPartsPricesFilePayload,
} from '@/shared/types/controllers/spa/SPASupplierPriceControllerTypes';
import { ListSupplierPriceSetsResponse } from '@/shared/types/controllers/spa/SPASupplierPriceSetControllerTypes';
import {
  GetSupportedManufacturerListResponse,
  GetSupportedManufacturerListPayload,
  CreateSupportedManufacturerPayload,
  GetSupportedManufacturersByNamePayload,
  GetSupportedManufacturersByNameResponse,
  GetManufacturerStats,
} from '@/shared/types/controllers/spa/SPASupportedManufacturerControllerTypes';
import {
  GetSupportedPartCategoryListResponse,
  GetSupportedPartCategoryListPayload,
  CreateSupportedPartCategoryBatchPayload,
  CreateSupportedPartCategoryPayload,
  CreateSupportedPartCategoryResponse,
} from '@/shared/types/controllers/spa/SPASupportedPartCategoryControllerTypes';
import {
  CreateSupportedProductLineBatchPayload,
  CreateSupportedProductLineResponse,
  CreateSupportedProductLineRequest,
} from '@/shared/types/controllers/spa/SPASupportedProductLineControllerTypes';
import {
  CreateTaskPayload,
  GetListDataPayload,
  GetListDataResponse,
  GetTaskResponse,
  UpdateTaskPayload,
} from '@/shared/types/controllers/spa/SPATaskControllerTypes';
import {
  CurrentUserInfoResponse,
  UserUploadProfileImagePayload,
  CreateCustomerUserFromContactPayload,
  CreateCustomerUserPayload,
} from '@/shared/types/controllers/spa/SPAUserControllerTypes';
import { GetTenantConfigForTenantAliasResponse } from '@/shared/types/controllers/sps/SPSAppControllerTypes';
import {
  deleteRequestPromise,
  getRequestPromise,
  postRequestPromise,
  uploadRequestPromise,
} from './RequestPromise';
import {
  getSocketRequestPromise,
  postSocketRequestPromise,
} from './SocketRequestPromise';

export const spaQueries = {
  app: {
    tenantConfig: (tenantAlias: string) => ({
      staleTime: 60_000 * 5,
      queryKey: ['app', 'tenantConfig', tenantAlias],
      queryFn: () =>
        getRequestPromise<GetTenantConfigForTenantAliasResponse>(
          `${process.env.API_HOST}/api/sps/${tenantAlias}/tenantConfig`,
        ).then((data) => data.tenantConfig),
    }),
    update: (tenantAlias: string, appID: number) => ({
      mutationFn: (payload: AppDisplayPayload) =>
        postRequestPromise<void, AppDisplayPayload>(
          `${getRestApiURLforTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/app/${appID}`,
          payload,
        ),
    }),
    updateShippingCutoff: (tenantAlias: string) => ({
      mutationFn: (payload: AppUpdateShippingCutoff) =>
        postRequestPromise<void, AppUpdateShippingCutoff>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/app/updateShippingCutoff`,
          payload,
        ),
    }),
    spaConfig: (tenantAlias: string) => ({
      queryKey: ['app', 'spaConfig'],
      queryFn: () =>
        getRequestPromise<AppSPAConfig>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/app/SPAConfig`,
        ).then((res) => res.spaConfig),
    }),
    // Does not require the user to be logged in
    publicSPAConfig: (tenantAlias: string) => ({
      queryKey: ['app', 'publicSPAConfig'],
      queryFn: () =>
        getRequestPromise<AppPublicSPAConfig>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/app/publicSPAConfig`,
        ).then((res) => res.spaConfig),
    }),
  },
  auth: {
    login: (tenantAlias: string) => ({
      mutationFn: (payload: LoginPayload) =>
        postRequestPromise<LoginResponse, LoginPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/auth/login`,
          payload,
        ),
    }),
    logout: (tenantAlias: string) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/auth/logout`,
          undefined,
          'Unable to logout',
        ),
    }),
    signup: (tenantAlias: string) => ({
      mutationFn: (payload: SignupPayload) =>
        postRequestPromise<void, SignupPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/auth/signup`,
          payload,
        ),
    }),
    forgotPassword: (tenantAlias: string) => ({
      mutationFn: (payload: ForgotPasswordPayload) =>
        postRequestPromise<void, ForgotPasswordPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/auth/forgotPassword`,
          payload,
        ),
    }),
  },

  certificate: {
    queryKey: ['certificate'],
    certificateList: (
      tenantAlias: string,
      queryParams: GetCertificateListPayload,
    ) => ({
      queryKey: ['certificateList', queryParams],
      queryFn: () =>
        getRequestPromise<GetCertificateListResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/certificate`,
          { params: queryParams },
        ),
    }),
    certificateApproval: (tenantAlias: string) => ({
      mutationFn: (payload: SetCertificateApprovalPayload) =>
        postRequestPromise<void, SetCertificateApprovalPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/certificate/${payload.certificateID}/setApproval`,
          payload,
        ),
    }),
  },
  company: {
    queryKey: ['company'],
    addToPaymentTermNames: (tenantAlias: string) => ({
      mutationFn: (payload: AddToPaymentTermNamesPayload) =>
        postRequestPromise<void, AddToPaymentTermNamesPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/addToPaymentTermNames`,
          payload,
        ),
    }),
    companyInfo: (tenantAlias: string, companyID: number) => ({
      queryKey: ['company', 'companyInfo', companyID],
      queryFn: () =>
        getRequestPromise<CompanyInfoResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/${companyID}/companyInfo`,
        ),
    }),
    logo: (tenantAlias: string, companyID: number) => ({
      queryKey: ['company', 'logo', companyID],
      queryFn: () =>
        getRequestPromise<CompanyLogoResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/${companyID}/logo`,
        ).then((data) => data.url),
    }),
    allUsersAsOptions: (tenantAlias: string) => ({
      queryKey: ['company', 'allUsersAsOptions'],
      queryFn: () =>
        getRequestPromise<CompanyAllUsersAsOptions>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/allUsersAsOptions`,
        ),
    }),
    updateIsInitialQuoteNotify: (tenantAlias: string) => ({
      mutationFn: (payload: CompanyUpdateIsInitialQuoteNotifyPayload) =>
        postRequestPromise<void, CompanyUpdateIsInitialQuoteNotifyPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/updateIsInitialQuoteNotify`,
          payload,
        ),
    }),
    updateIsInitialPONotify: (tenantAlias: string) => ({
      mutationFn: (payload: CompanyUpdateIsInitialPONotifyPayload) =>
        postRequestPromise<void, CompanyUpdateIsInitialPONotifyPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/updateIsInitialPONotify`,
          payload,
        ),
    }),
    updateIsInitialConversationNotify: (tenantAlias: string) => ({
      mutationFn: (payload: CompanyUpdateIsInitialConversationNotifyPayload) =>
        postRequestPromise<
          void,
          CompanyUpdateIsInitialConversationNotifyPayload
        >(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/updateIsInitialConversationNotify`,
          payload,
        ),
    }),
    getSupportedManufacturers: (
      tenantAlias: string,
      params: GetSupportedManufacturerListPayload,
    ) => ({
      queryKey: ['company', 'getSupportedManufacturers', params],
      queryFn: () =>
        getRequestPromise<GetSupportedManufacturerListResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedManufacturer`,
          { params },
        ),
    }),
    getManufacturerHasPartCategoryAndProductLine: (
      tenantAlias: string,
      params: GetManufacturerHasPartCategoryAndProductLinePayload,
    ) => ({
      queryKey: [
        'company',
        'getManufacturerHasPartCategoryAndProductLine',
        params,
      ],
      queryFn: () =>
        getRequestPromise<GetManufacturerHasPartCategoryAndProductLineResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/getManufacturerHasPartCategoryAndProductLine`,
          { params },
        ),
    }),
    getSupportedManufacturerByName: (
      tenantAlias: string,
      params: GetSupportedManufacturersByNamePayload,
    ) => ({
      queryKey: ['company', 'getSupportedManufacturerByName', params],
      queryFn: () =>
        getRequestPromise<GetSupportedManufacturersByNameResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedManufacturer/byName`,
          { params },
        ),
    }),
    getManufacturerStats: (tenantAlias: string, manufacturerID: number) => ({
      queryKey: ['company', 'getManufacturerStats', manufacturerID],
      queryFn: () =>
        getRequestPromise<GetManufacturerStats>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/${manufacturerID}/stats`,
        ),
    }),
    createSupportedManufacturer: (tenantAlias: string) => ({
      mutationFn: (payload: CreateSupportedManufacturerPayload) =>
        postRequestPromise<void, CreateSupportedManufacturerPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedManufacturer`,
          payload,
        ),
    }),
    deleteSupportedManufacturer: (tenantAlias: string) => ({
      mutationFn: (manufacturerID: number) =>
        deleteRequestPromise<void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedManufacturer/${manufacturerID}`,
        ),
    }),
    updateProfitMargins: (tenantAlias: string) => ({
      mutationFn: (payload: UpdateProfitMarginsPayload) =>
        postRequestPromise<void, UpdateProfitMarginsPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/updateProfitMargins`,
          payload,
        ),
    }),
    settings: {
      usersData: (tenantAlias: string) => ({
        queryKey: ['company', 'settings', 'usersData'],
        queryFn: () =>
          getRequestPromise<CompanySettingsUsersDataResponse>(
            `${getSPAApiURLForTenant(
              tenantAlias,
              getHostFromWindow(),
            )}/company/settings/usersData`,
          ),
      }),
      marketplaceData: (tenantAlias: string) => ({
        queryKey: ['company', 'settings', 'marketplaceData'],
        queryFn: () =>
          getRequestPromise<CompanySettingsMarketplaceDataResponse>(
            `${getSPAApiURLForTenant(
              tenantAlias,
              getHostFromWindow(),
            )}/company/settings/marketplaceData`,
          ),
      }),
      supplierData: (tenantAlias: string) => ({
        queryKey: ['company', 'settings', 'supplierData'],
        queryFn: () =>
          getRequestPromise<CompanySettingsSupplierDataResponse>(
            `${getSPAApiURLForTenant(
              tenantAlias,
              getHostFromWindow(),
            )}/company/settings/supplierData`,
          ),
      }),
      integrationsData: (tenantAlias: string) => ({
        queryKey: ['company', 'settings', 'integrationsData'],
        queryFn: () =>
          getRequestPromise<CompanySettingsIntegrationsDataResponse>(
            `${getSPAApiURLForTenant(
              tenantAlias,
              getHostFromWindow(),
            )}/company/settings/integrationsData`,
          ),
      }),
      serpSettings: (tenantAlias: string) => ({
        queryKey: ['company', 'settings', 'serpSettings'],
        queryFn: () =>
          getRequestPromise<GetSerpSettingsResponse>(
            `${getSPAApiURLForTenant(
              tenantAlias,
              getHostFromWindow(),
            )}/serpSettings`,
          ),
      }),
    },
  },
  contact: {
    findOne: (tenantAlias: string, contactID: number) => ({
      queryKey: ['contact', contactID],
      queryFn: () =>
        getRequestPromise<ContactFindOneResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/contact/${contactID}`,
        ),
    }),
    findOneByEmail: (
      tenantAlias: string,
      params: ContactFindOneByEmailPayload,
    ) => ({
      queryKey: ['contact', 'findOneByEmail', params],
      queryFn: () =>
        getRequestPromise<ContactFindOneByEmailResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/contact/findOneByEmail`,
          { params },
        ),
    }),
    listContacts: (tenantAlias: string, params?: ContactListPayload) => ({
      queryKey: ['contact', 'listContacts', params],
      queryFn: () =>
        getRequestPromise<ContactListResponse>(
          `${getSPAApiURLForTenant(tenantAlias, getHostFromWindow())}/contact`,
          { params },
        ),
    }),
    createContact: (tenantAlias: string) => ({
      mutationFn: (payload: ContactCreatePayload) =>
        postRequestPromise<ContactCreateResponse, ContactCreatePayload>(
          `${getSPAApiURLForTenant(tenantAlias, getHostFromWindow())}/contact`,
          payload,
        ),
    }),
    createForCompany: (tenantAlias: string) => ({
      mutationFn: (payload: ContactCreateForCompanyPayload) =>
        postRequestPromise<
          ContactCreateForCompanyResponse,
          ContactCreateForCompanyPayload
        >(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/contact/createForCompany`,
          payload,
        ),
    }),
    updateContact: (tenantAlias: string, contactId: number) => ({
      mutationFn: (payload: ContactUpdatePayload) =>
        postRequestPromise<void, ContactUpdatePayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/contact/${contactId}`,
          payload,
        ),
    }),
    sendInviteEmail: (tenantAlias: string, contactId: number) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/contact/${contactId}/sendInviteEmail`,
        ),
    }),
  },
  conversation: {
    hasUnreadConversation: (tenantAlias: string) => ({
      queryKey: ['conversation', 'hasUnreadConversation'],
      queryFn: () =>
        getSocketRequestPromise<
          void,
          ConversationHasUnreadConversationResponse
        >(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/conversation/hasUnreadConversation`,
        ).then((data) => data.hasUnreadConversation),
    }),
    createAppConversation: (tenantAlias: string) => ({
      mutationFn: (payload: ConversationCreateAppConversationPayload) =>
        postRequestPromise<
          ConversationCreateAppConversationResponse,
          ConversationCreateAppConversationPayload
        >(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/conversation/createAppConversation`,
          payload,
        ),
    }),
    displayData: (conversationID: number, tenantAlias: string) => ({
      queryKey: ['conversation', 'displayData', conversationID],
      queryFn: () =>
        getSocketRequestPromise<void, ConversationDisplayDataResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/conversation/${conversationID}/displayData`,
        ).then((data) => data?.conversation),
      enabled: Boolean(conversationID),
    }),
    typing: (conversationID: number, tenantAlias: string) => ({
      mutationFn: () =>
        postSocketRequestPromise(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/conversation/${conversationID}/typing`,
        ),
    }),
    join: (conversationID: number, tenantAlias: string) => ({
      mutationFn: () => {
        return postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/conversation/${conversationID}/join`,
          undefined,
        );
      },
    }),
  },
  couponCode: {
    queryKey: ['couponCode'],
    get: (couponCodeID: number, tenantAlias: string) => ({
      queryKey: ['couponCode', 'get', couponCodeID],
      queryFn: () =>
        getRequestPromise<CouponCodeGetResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/couponCode/${couponCodeID}`,
        ),
    }),
    create: (tenantAlias: string) => ({
      mutationFn: (payload: CouponCodeCreateCouponCodePayload) =>
        postRequestPromise<void, CouponCodeCreateCouponCodePayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/couponCode`,
          payload,
        ),
    }),
    update: (tenantAlias: string) => ({
      mutationFn: ({
        couponCodeID,
        payload,
      }: {
        couponCodeID: number;
        payload: CouponCodeUpdateCouponCodePayload;
      }) =>
        postRequestPromise<void, CouponCodeUpdateCouponCodePayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/couponCode/${couponCodeID}`,
          payload,
        ),
    }),
    list: (tenantAlias: string) => ({
      queryKey: ['couponCode', 'list'],
      queryFn: () =>
        getRequestPromise<CouponCodeListCouponCodesResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/couponCode`,
        ),
    }),
  },
  customPart: {
    create: (tenantAlias: string) => ({
      mutationFn: (payload: CreateCustomPartPayload) =>
        postRequestPromise<CreateCustomPartResponse, CreateCustomPartPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/customPart`,
          payload,
        ),
    }),
  },
  customerPrice: {
    queryKey: ['customerPrice'],
    dashboard: (
      tenantAlias: string,
      params?: GetCustomerPriceDashboardPayload,
    ) => ({
      queryKey: ['customerPrice', 'dashboard', params],
      queryFn: () =>
        getRequestPromise<GetCustomerPriceDashboardResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/customerPrice/dashboard`,
          { params },
        ),
    }),
    get: (tenantAlias: string, customerPriceID: number) => ({
      queryKey: ['customerPrice', 'get', customerPriceID],
      queryFn: () =>
        getRequestPromise<GetCustomerPriceResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/customerPrice/${customerPriceID}`,
        ),
    }),
    create: (tenantAlias: string) => ({
      mutationFn: (payload: CreateCustomerPricePayload) =>
        postRequestPromise<void, CreateCustomerPricePayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/customerPrice`,
          payload,
        ),
    }),
    update: (tenantAlias: string) => ({
      mutationFn: ({
        customerPriceID,
        payload,
      }: {
        customerPriceID: number;
        payload: UpdateCustomerPricePayload;
      }) =>
        postRequestPromise<void, UpdateCustomerPricePayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/customerPrice/${customerPriceID}`,
          payload,
        ),
    }),
    delete: (tenantAlias: string) => ({
      mutationFn: (customerPriceID: number) =>
        deleteRequestPromise<void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/customerPrice/${customerPriceID}`,
        ),
    }),
    pricingBreakdownByManufacturer: (
      tenantAlias: string,
      params: GetPricingBreakdownByManufacturerPayload,
    ) => ({
      queryKey: ['customerPrice', 'pricingBreakdownByManufacturer', params],
      queryFn: () =>
        getRequestPromise<GetPricingBreakdownByManufacturerResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/customerPrice/pricingBreakdownByManufacturer`,
          { params },
        ),
    }),
  },
  dashboard: {
    queryKey: ['dashboard'],
    supplierData: (tenantAlias: string) => ({
      queryKey: ['dashboard', 'supplierData', tenantAlias],
      queryFn: () =>
        getRequestPromise<SupplierDashboardDataResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierDashboardData`,
        ),
    }),
  },
  integration: {
    erpShippingMethods: (tenantAlias: string) => ({
      queryKey: ['integration', 'erpShippingMethods'],
      queryFn: () =>
        getRequestPromise<GetErpShippingMethodsResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/integration/erpShippingMethods`,
        ),
    }),
    getOctopartIntegration: (tenantAlias: string) => ({
      queryKey: ['integration', 'getOctopartIntegration'],
      queryFn: () =>
        getRequestPromise<GetOctopartIntegrationResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/integration/getOctopartIntegration`,
        ),
    }),
    upsertOctopartIntegration: (tenantAlias: string) => ({
      mutationFn: (payload: UpsertOctopartIntegrationPayload) =>
        postRequestPromise<void, UpsertOctopartIntegrationPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/integration/upsertOctopartIntegration`,
          payload,
        ),
    }),
    getGoogleMerchantIntegration: (tenantAlias: string) => ({
      queryKey: ['integration', 'getGoogleMerchantIntegration'],
      queryFn: () =>
        getRequestPromise<GetGoogleMerchantIntegrationResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/integration/getGoogleMerchantIntegration`,
        ),
    }),
    upsertGoogleMerchantIntegration: (tenantAlias: string) => ({
      mutationFn: (payload: UpsertGoogleMerchantIntegrationPayload) =>
        postRequestPromise<void, UpsertGoogleMerchantIntegrationPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/integration/upsertGoogleMerchantIntegration`,
          payload,
        ),
    }),
    getPartsDirectIntegration: (tenantAlias: string) => ({
      queryKey: ['integration', 'getPartsDirectIntegration'],
      queryFn: () =>
        getRequestPromise<GetPartsDirectIntegrationResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/integration/getPartsDirectIntegration`,
        ),
    }),
    upsertPartsDirectIntegration: (tenantAlias: string) => ({
      mutationFn: (payload: UpsertPartsDirectIntegrationPayload) =>
        postRequestPromise<void, UpsertPartsDirectIntegrationPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/integration/upsertPartsDirectIntegration`,
          payload,
        ),
    }),
  },
  inventory: {
    updateCount: (tenantAlias: string, inventoryID: number) => ({
      mutationFn: (payload: PartInventoryUpdateCountPayload) =>
        postRequestPromise<
          PartInventoryUpdateCountResponse,
          PartInventoryUpdateCountPayload
        >(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/inventory/${inventoryID}/updateCount`,
          payload,
        ),
    }),
  },
  navbar: {
    queryKey: ['navbar'],
    displayData: (tenantAlias: string) => ({
      queryKey: ['navbar', 'index'],
      queryFn: () =>
        getRequestPromise<NavbarGetDataResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/navbar/data`,
        ),
    }),
  },
  notificationPolicy: {
    upsert: (tenantAlias: string) => ({
      mutationFn: (payload: NotificationPolicyUpsertPayload) =>
        postRequestPromise<void, NotificationPolicyUpsertPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/notificationPolicy/upsert`,
          payload,
          'Unable to modify notification settings at this time. Please try again later',
        ),
    }),
  },
  order: {
    queryKey: ['order'],
    updateOrderAddress: (orderID: number, tenantAlias: string) => ({
      mutationFn: (payload: UpdateOrderAddressPayload) =>
        postRequestPromise<void, UpdateOrderAddressPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/updateOrderAddress`,
          payload,
        ),
    }),
    received: (queryParams: OrderListPayload, tenantAlias: string) => ({
      queryKey: ['order', 'received', queryParams],
      queryFn: () =>
        getRequestPromise<OrderListResponse>(
          `${getSPAApiURLForTenant(tenantAlias, getHostFromWindow())}/order`,
          { params: queryParams },
        ),
    }),
    displayData: (orderID: number, tenantAlias: string) => ({
      queryKey: ['order', orderID, 'displayData'],
      queryFn: () =>
        getRequestPromise<OrderGetOrderResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}`,
        ),
      enabled: Boolean(orderID),
    }),
    setManualSupplierIntegration: (orderID: number, tenantAlias: string) => ({
      mutationFn: (payload: SetManualSupplierIntegrationPayload) =>
        postRequestPromise<void, SetManualSupplierIntegrationPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/manualSupplierIntegration`,
          payload,
        ),
    }),
    customerDetails: (orderID: number, tenantAlias: string) => ({
      queryKey: ['order', orderID, 'customerDetails'],
      queryFn: () =>
        getRequestPromise<OrderCustomerDetailsResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/customerDetails`,
        ),
    }),
    recentBuyerOrders: (
      payload: OrderRecentBuyerOrdersPayload,
      tenantAlias: string,
    ) => ({
      queryKey: ['order', 'recentBuyerOrders', payload],
      queryFn: () =>
        getRequestPromise<OrderRecentBuyerOrdersResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/recentBuyerOrders`,
          { params: payload },
        ),
    }),
    accept: (orderID: number, tenantAlias: string) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/accept`,
          undefined,
          'There was an error updating order status, please try again',
        ),
    }),
    deny: (orderID: number, tenantAlias: string) => ({
      mutationFn: (payload: OrderDenyPayload) =>
        postRequestPromise<void, OrderDenyPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/deny`,
          payload,
          'There was an error updating order status, please try again',
        ),
    }),
    partialShip: (orderID: number, tenantAlias: string) => ({
      mutationFn: (payload: OrderPartialShipPayload) =>
        postRequestPromise<void, OrderPartialShipPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/partialShip`,
          payload,
          'There was an error updating order status, please try again',
        ),
    }),
    updateSupplierNotes: (orderID: number, tenantAlias: string) => ({
      mutationFn: (payload: OrderUpdateSupplierNotesPayload) =>
        postRequestPromise<void, OrderUpdateSupplierNotesPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/updateSupplierNotes`,
          payload,
          'There was an error updating the note. Please try again later',
        ),
    }),
    validateIntegrationException: (tenantAlias: string) => ({
      mutationFn: ({
        orderID,
        integrationExceptionID,
      }: {
        orderID: number;
        integrationExceptionID: number;
      }) =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/validateAndResolveIntegrationException/${integrationExceptionID}`,
        ),
    }),
    integrationExceptions: (orderID: number, tenantAlias: string) => ({
      queryKey: ['order', orderID, 'integrationExceptions'],
      queryFn: () =>
        getRequestPromise<OrderGetIntegrationExceptionsResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/integrationExceptions`,
        ),
    }),
    createSupplierErpOrder: (orderID: number, tenantAlias: string) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/supplierErpOrder`,
        ),
    }),
    overrideIntegrationException: (tenantAlias: string) => ({
      mutationFn: ({
        orderID,
        integrationExceptionID,
      }: {
        orderID: number;
        integrationExceptionID: number;
      }) =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/overrideIntegrationException/${integrationExceptionID}`,
        ),
    }),
    applyIntegrationPaymentTerms: (
      orderID: number,
      integrationExceptionID: number,
      tenantAlias: string,
    ) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/applyIntegrationPaymentTerms/${integrationExceptionID}`,
        ),
    }),
    updateShippingMethod: (
      orderID: number,
      integrationExceptionID: number,
      tenantAlias: string,
    ) => ({
      mutationFn: (payload: OrderUpdateShippingMethodPayload) =>
        postRequestPromise<void, OrderUpdateShippingMethodPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/updateShippingMethod/${integrationExceptionID}`,
          payload,
        ),
    }),
    setTaxAndShippingOverride: (orderID: number, tenantAlias: string) => ({
      mutationFn: (payload: SetTaxAndShippingOverridePayload) =>
        postRequestPromise<void, SetTaxAndShippingOverridePayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/setTaxAndShippingOverride`,
          payload,
          'There was an error updating the order. Please try again later',
        ),
    }),
    cancelledItems: (orderID: number, tenantAlias: string) => ({
      queryKey: ['order', orderID, 'displayCancelledItemsData'],
      queryFn: () =>
        getRequestPromise<DisplayCancelledItemsDataResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/order/${orderID}/displayCancelledItemsData`,
        ),
    }),
  },
  orderItem: {
    queryKey: ['orderItem'],
    cancelItem: (orderItemID: number, tenantAlias: string) => ({
      mutationFn: () =>
        deleteRequestPromise(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/orderItem/${orderItemID}`,
          {},
          'There was an error canceling order item, please try again',
        ),
    }),
    updateInstanceSupplier: (orderItemID: number, tenantAlias: string) => ({
      mutationFn: (payload: OrderItemUpdatePayload) =>
        postRequestPromise<void, OrderItemUpdatePayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/orderItem/${orderItemID}`,
          payload,
          'There was an error updating order details, please try again',
        ),
    }),
  },
  part: {
    publicData: (partID: number, tenantAlias: string) => ({
      queryKey: ['part', 'publicData', partID],
      queryFn: () =>
        getRequestPromise<PartPublicDataResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/part/${partID}/publicData`,
        ),
    }),
  },
  partCategory: {
    autocomplete: (
      tenantAlias: string,
      params: PartCategoryAutocompletePayload,
    ) => ({
      queryKey: ['partCategory', 'autocomplete', params],
      queryFn: () =>
        getRequestPromise<PartCategoryAutocompleteResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/partCategory/autocomplete`,
          { params },
        ),
    }),
  },
  partRequest: {
    queryKey: ['partRequest'],
    create: (tenantAlias: string) => ({
      mutationFn: (payload: CreatePartRequestPayload) =>
        postRequestPromise<void, CreatePartRequestPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/partRequest`,
          payload,
        ),
    }),
    remove: (tenantAlias: string) => ({
      mutationFn: (partRequestID: number) =>
        deleteRequestPromise<void>(
          `${getRestApiURLforTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/partRequest/${partRequestID}`,
        ),
    }),
    updateRanking: (tenantAlias: string) => ({
      mutationFn: ({
        partRequestID,
        payload,
      }: {
        partRequestID: number;
        payload: PartRequestUpdateRankingPayload;
      }) =>
        postRequestPromise<void, PartRequestUpdateRankingPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/partRequest/${partRequestID}/updateRanking`,
          payload,
        ),
    }),
    updatePriceAndQty: (partRequestID: number, tenantAlias: string) => ({
      mutationFn: (payload: PartRequestUpdatePriceAndQtyPayload) =>
        postRequestPromise<void, PartRequestUpdatePriceAndQtyPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/partRequest/${partRequestID}/updatePriceAndQty`,
          payload,
          'Unable to update quote. Please try again later',
        ),
    }),
    suggestedPricing: (tenantAlias: string, partRequestID: number) => ({
      queryKey: ['partRequest', partRequestID, 'suggestedPricing'],
      queryFn: () =>
        getRequestPromise<GetPartRequestSuggestedPricingResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/partRequest/${partRequestID}/suggestedPricing`,
        ),
    }),
  },
  paymentTerm: {
    queryKey: ['paymentTerm'],
    get: (params: GetPaymentTermsRequestParams, tenantAlias: string) => ({
      queryKey: ['paymentTerm', 'get', params],
      queryFn: () =>
        getRequestPromise<GetPaymentTermsResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/paymentTerm`,
          { params },
        ),
    }),
    dashboard: (
      tenantAlias: string,
      params?: GetPaymentTermsDashboardPayload,
    ) => ({
      queryKey: ['paymentTerm', 'dashboard', params],
      queryFn: () =>
        getRequestPromise<GetPaymentTermsDashboardResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/paymentTerm/dashboard`,
          { params },
        ),
    }),
    createPaymentTerm: (tenantAlias: string) => ({
      mutationFn: (payload: CreatePaymentTermPayload) =>
        postRequestPromise<void, CreatePaymentTermPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/paymentTerm`,
          payload,
        ),
    }),
    updatePaymentTerm: (tenantAlias: string) => ({
      mutationFn: (payload: UpdatePaymentTermPayload) =>
        postRequestPromise<void, UpdatePaymentTermPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/paymentTerm/${payload.paymentTermID}`,
          payload,
        ),
    }),
    deletePaymentTerm: (tenantAlias: string) => ({
      mutationFn: (paymentTermID: number) =>
        deleteRequestPromise(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/paymentTerm/${paymentTermID}`,
        ),
    }),
  },
  productLine: {
    getProductLinesByManufacturer: (
      tenantAlias: string,
      params: GetProductLinesByManufacturerRequest,
    ) => ({
      queryKey: ['company', 'getProductLinesByManufacturer', params],
      queryFn: () =>
        getRequestPromise<GetProductLinesByManufacturerResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/productLine`,
          { params },
        ),
    }),
    autocomplete: (
      tenantAlias: string,
      params: ProductLineAutocompletePayload,
    ) => ({
      queryKey: ['productLine', 'autocomplete', params],
      queryFn: () =>
        getRequestPromise<ProductLineAutocompleteResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/productLine/autocomplete`,
          { params },
        ),
    }),
  },
  quote: {
    queryKey: ['quote'],
    displayData: (quoteID: number, tenantAlias: string) => ({
      queryKey: ['quote', quoteID, 'displayData'],
      queryFn: () =>
        getRequestPromise<QuoteDisplayDataResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/displayData`,
        ),
      enabled: Boolean(quoteID),
    }),
    subscribe: (tenantAlias: string, quoteID: number) => ({
      queryKey: ['quote', 'subscribe', quoteID],
      queryFn: () =>
        getSocketRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/subscribe`,
        ),
    }),
    unsubscribe: (tenantAlias: string, quoteID: number) => ({
      queryKey: ['quote', 'unsubscribe', quoteID],
      queryFn: () =>
        getSocketRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/unsubscribe`,
        ),
    }),
    noBid: (quoteID: number, tenantAlias: string) => ({
      mutationFn: (payload: QuoteNoBidPayload) =>
        postRequestPromise<void, QuoteNoBidPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/noBid`,
          payload,
        ),
    }),
    yesBid: (quoteID: number, tenantAlias: string) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/yesBid`,
        ),
    }),
    submit: (quoteID: number, tenantAlias: string) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/submit`,
        ),
    }),
    reopen: (quoteID: number, tenantAlias: string) => ({
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/reopen`,
        ),
    }),
    updateQuoteNote: (quoteID: number, tenantAlias: string) => ({
      mutationFn: (payload: QuoteUpdateQuoteNotePayload) =>
        postRequestPromise<void, QuoteUpdateQuoteNotePayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/updateQuoteNote`,
          payload,
        ),
    }),
    updateNotesSupplier: (quoteID: number, tenantAlias: string) => ({
      mutationFn: (payload: QuoteUpdateNotesSupplierPayload) =>
        postRequestPromise<void, QuoteUpdateNotesSupplierPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/updateNotesSupplier`,
          payload,
        ),
    }),
    updateAssignee: (quoteID: number, tenantAlias: string) => ({
      mutationFn: (payload: QuoteUpdateAssigneePayload) =>
        postRequestPromise<void, QuoteUpdateAssigneePayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/updateAssignee`,
          payload,
        ),
    }),
    updateValidityDuration: (quoteID: number, tenantAlias: string) => ({
      mutationFn: (payload: QuoteUpdateValidityDurationPayload) =>
        postRequestPromise<void, QuoteUpdateValidityDurationPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/${quoteID}/updateValidityDuration`,
          payload,
        ),
    }),
    listData: (tenantAlias: string, params?: QuoteListDataPayload) => ({
      queryKey: ['quote', 'listData', params],
      queryFn: () =>
        getRequestPromise<QuoteListDataResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/listData`,
          { params },
        ),
    }),
    recentBuyerQuotes: (
      payload: QuoteRecentBuyerQuotesPayload,
      tenantAlias: string,
    ) => ({
      queryKey: ['quote', 'recentBuyerQuotes', payload],
      queryFn: () =>
        getRequestPromise<QuoteRecentBuyerQuotesResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/recentBuyerQuotes`,
          { params: payload },
        ),
    }),
    recentContactQuotes: (
      tenantAlias: string,
      payload: QuoteRecentContactQuotesPayload,
    ) => ({
      queryKey: ['quote', 'recentContactQuotes', payload],
      queryFn: () =>
        getRequestPromise<QuoteRecentContactQuotesResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/recentContactQuotes`,
          { params: payload },
        ),
    }),
    createForContact: (tenantAlias: string) => ({
      mutationFn: (payload: CreateForContactPayload) =>
        postRequestPromise<void, CreateForContactPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quote/createForContact`,
          payload,
        ),
    }),
  },
  quoteFee: {
    queryKey: ['quoteFee'],
    create: (tenantAlias: string) => ({
      mutationFn: (payload: QuoteFeeCreateQuoteFeePayload) =>
        postRequestPromise<
          QuoteFeeCreateQuoteFeeResponse,
          QuoteFeeCreateQuoteFeePayload
        >(
          `${getRestApiURLforTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quoteFee`,
          payload,
        ),
    }),
    update: (tenantAlias: string) => ({
      mutationFn: ({
        quoteFeeID,
        payload,
      }: {
        quoteFeeID: number;
        payload: QuoteFeeUpdateQuoteFeePayload;
      }) =>
        postRequestPromise<
          QuoteFeeUpdateQuoteFeeResponse,
          QuoteFeeUpdateQuoteFeePayload
        >(
          `${getRestApiURLforTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quoteFee/${quoteFeeID}`,
          payload,
        ),
    }),
    delete: (tenantAlias: string) => ({
      mutationFn: (quoteFeeID: number) =>
        deleteRequestPromise<void>(
          `${getRestApiURLforTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/quoteFee/${quoteFeeID}`,
        ),
    }),
  },
  salesTerritory: {
    queryKey: ['salesTerritory'],
    setSalesTerritoryBatch: (tenantAlias: string) => ({
      mutationFn: (payload: SalesTerritorySetSalesTerritoryBatchPayload) =>
        postRequestPromise<void, SalesTerritorySetSalesTerritoryBatchPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/salesTerritory/setSalesTerritoryBatch`,
          payload,
        ),
    }),
    getSalesTerritoryForManufacturer: (
      tenantAlias: string,
      params: GetSalesTerritoriesForManufacturerPayload,
    ) => ({
      queryFn: () =>
        getRequestPromise<GetSalesTerritoriesForManufacturerResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/salesTerritory/getSalesTerritoryForManufacturer`,
          { params },
        ),
    }),
    setGeneralSalesTerritoryBatch: (tenantAlias: string) => ({
      mutationFn: (
        payload: SalesTerritorySetGeneralSalesTerritoryBatchPayload,
      ) =>
        postRequestPromise<
          void,
          SalesTerritorySetGeneralSalesTerritoryBatchPayload
        >(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/salesTerritory/setGeneralSalesTerritoryBatch`,
          payload,
        ),
    }),
    getGeneralSalesTerritories: (tenantAlias: string) => ({
      queryKey: ['salesTerritory', 'getGeneralSalesTerritories'],
      queryFn: () =>
        getRequestPromise<GetGeneralSalesTerritoriesResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/salesTerritory/getGeneralSalesTerritories`,
        ),
    }),
  },
  salesRep: {
    queryKey: ['salesRep'],
    setSalesRep: (tenantAlias: string) => ({
      mutationFn: (payload: SetSalesRepPayload) =>
        postRequestPromise<void, SetSalesRepPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/salesRep/setSalesRep`,
          payload,
        ),
    }),
  },
  search: {
    queryKey: ['search'],
    companyByUserAutocomplete: (
      params: CompanyByUserAutocompletePayload,
      tenantAlias: string,
    ) => ({
      queryKey: ['search', 'companyByUserAutocomplete', params],
      queryFn: () =>
        getRequestPromise<CompanyByUserAutocompleteResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/search/companyByUserAutocomplete`,
          { params },
        ),
    }),
    manufacturerAutocomplete: (
      params: ManufacturerAutocompletePayload,
      tenantAlias: string,
    ) => ({
      queryKey: ['search', 'manufacturerAutocomplete', params],
      queryFn: () =>
        getRequestPromise<ManufacturerAutocompleteResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/search/manufacturerAutocomplete`,
          { params },
        ),
    }),
    partAutocomplete: (
      params: PartAutocompletePayload,
      tenantAlias: string,
    ) => ({
      queryKey: ['search', 'partAutocomplete', params],
      queryFn: () =>
        getRequestPromise<PartAutocompleteResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/search/partAutocomplete`,
          { params },
        ).then((data) => data.parts),
    }),
    contactAutocomplete: (
      params: ContactAutocompletePayload,
      tenantAlias: string,
    ) => ({
      queryKey: ['contact', 'contactAutocomplete', params],
      queryFn: () =>
        getRequestPromise<ContactAutocompleteResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/search/contactAutocomplete`,
          { params },
        ),
    }),
    quoteAutocomplete: (
      params: QuoteAutocompletePayload,
      tenantAlias: string,
    ) => ({
      queryKey: ['search', 'quoteAutocomplete', params],
      queryFn: () =>
        getRequestPromise<QuoteAutocompleteResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/search/quoteAutocomplete`,
          { params },
        ),
    }),
    orderAutocomplete: (
      params: OrderAutocompletePayload,
      tenantAlias: string,
    ) => ({
      queryKey: ['search', 'orderAutocomplete', params],
      queryFn: () =>
        getRequestPromise<OrderAutocompleteResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/search/orderAutocomplete`,
          { params },
        ),
    }),
  },
  supplierPrice: {
    queryKey: ['supplierPrice'],
    uploadPartsPricesFile: (tenantAlias: string) => ({
      mutationFn: (payload: UploadPartsPricesFilePayload) =>
        uploadRequestPromise<void, UploadPartsPricesFilePayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierPrice/uploadPartsPricesFile`,
          payload,
        ),
    }),
    update: (tenantAlias: string, partPriceID: number) => ({
      mutationFn: (payload: SupplierPriceUpdateSupplierPricePayload) =>
        postRequestPromise<void, SupplierPriceUpdateSupplierPricePayload>(
          `${getRestApiURLforTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierPrice/${partPriceID}`,
          payload,
        ),
    }),
    getSupplierPriceDashboard: (
      tenantAlias: string,
      params?: GetSupplierPriceDashboardPayload,
    ) => ({
      queryKey: ['supplierPrice', 'getSupplierPriceDashboard', params],
      queryFn: () =>
        getRequestPromise<GetSupplierPriceDashboardResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierPrice/dashboard`,
          { params },
        ),
    }),
  },
  supplierPriceSet: {
    queryKey: ['supplierPriceSet'],
    list: (tenantAlias: string) => ({
      queryKey: ['supplierPriceSet', 'list'],
      queryFn: () =>
        getRequestPromise<ListSupplierPriceSetsResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierPriceSet`,
        ),
    }),
  },
  supportedPartCategory: {
    getSupportedPartCategory: (
      tenantAlias: string,
      params: GetSupportedPartCategoryListPayload,
    ) => ({
      queryKey: ['company', 'getSupportedPartCategory', params],
      queryFn: () =>
        getRequestPromise<GetSupportedPartCategoryListResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedPartCategory`,
          { params },
        ),
    }),
    uploadPartCategoryImage: (tenantAlias: string) => ({
      mutationFn: (payload: UploadPartCategoryImagePayload<File>) =>
        uploadRequestPromise<void, UploadPartCategoryImagePayload<File>>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierPartCategoryData/uploadPartCategoryImage`,
          payload,
        ),
    }),
    createSupportedPartCategory: (tenantAlias: string) => ({
      mutationFn: (payload: CreateSupportedPartCategoryPayload) =>
        postRequestPromise<
          CreateSupportedPartCategoryResponse,
          CreateSupportedPartCategoryPayload
        >(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedPartCategory`,
          payload,
        ),
    }),
    createSupportedPartCategoryBatch: (tenantAlias: string) => ({
      mutationFn: (payload: CreateSupportedPartCategoryBatchPayload) =>
        postRequestPromise<void, CreateSupportedPartCategoryBatchPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedPartCategory/batch`,
          payload,
        ),
    }),
    deleteSupportedPartCategory: (tenantAlias: string) => ({
      mutationFn: (supportedPartCategoryID: number) =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedPartCategory/${supportedPartCategoryID}`,
        ),
    }),
  },
  supportedProductLine: {
    createSupportedProductLine: (tenantAlias: string) => ({
      mutationFn: (payload: CreateSupportedProductLineRequest) =>
        postRequestPromise<
          CreateSupportedProductLineResponse,
          CreateSupportedProductLineRequest
        >(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedProductLine`,
          payload,
        ),
    }),
    createSupportedProductLineBatch: (tenantAlias: string) => ({
      mutationFn: (payload: CreateSupportedProductLineBatchPayload) =>
        postRequestPromise<void, CreateSupportedProductLineBatchPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedProductLine/batch`,
          payload,
        ),
    }),
    deleteSupportedProductLine: (tenantAlias: string) => ({
      mutationFn: (productLineID: number) =>
        postRequestPromise<void, void>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/company/supportedProductLine/${productLineID}`,
        ),
    }),
  },
  task: {
    create: (tenantAlias: string) => ({
      mutationFn: (payload: CreateTaskPayload) =>
        postRequestPromise<void, CreateTaskPayload>(
          `${getSPAApiURLForTenant(tenantAlias, getHostFromWindow())}/task`,
          payload,
        ),
    }),
    updateTask: (tenantAlias: string, id: number) => ({
      mutationFn: (payload: UpdateTaskPayload) =>
        postRequestPromise<void, UpdateTaskPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/task/${id}`,
          payload,
        ),
    }),
    getTask: (tenantAlias: string, id: number) => ({
      queryKey: ['task', 'getTask', id],
      queryFn: () =>
        getRequestPromise<GetTaskResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/task/${id}`,
        ),
    }),
    listData: (tenantAlias: string, params?: GetListDataPayload) => ({
      queryKey: ['task', 'listData', params],
      queryFn: () =>
        getRequestPromise<GetListDataResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/task/listData`,
          { params },
        ),
    }),
  },
  user: {
    queryKey: ['user'],
    getCurrentUserInfo: (tenantAlias: string) => ({
      queryKey: ['user', 'info'],
      queryFn: () => {
        return getRequestPromise<CurrentUserInfoResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/info`,
        );
      },
    }),
    createCustomerUserFromContact: (tenantAlias: string) => ({
      mutationFn: (payload: CreateCustomerUserFromContactPayload) =>
        postRequestPromise<void, CreateCustomerUserFromContactPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/createCustomerUserFromContact`,
          payload,
        ),
    }),
    allAssignees: (tenantAlias: string) => ({
      queryKey: ['user', 'allAssignees'],
      queryFn: () =>
        getRequestPromise<UserAllAssigneesResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/allAssignees`,
        ),
    }),
    getProfileImage: (tenantAlias: string, userID: number | undefined) => ({
      queryKey: ['user', 'getProfileImage', userID],
      queryFn: () =>
        getRequestPromise<GetProfileImageResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/${userID}/profileImage`,
        ).then((data) => data.url),
    }),
    disableUser: (tenantAlias: string) => ({
      mutationFn: (userID: number) =>
        postSocketRequestPromise<void, DisableUserPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/${userID}/disableUser`,
        ),
    }),
    createCustomerUser: (tenantAlias: string) => ({
      mutationFn: (payload: CreateCustomerUserPayload) =>
        postRequestPromise<void, CreateCustomerUserPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/createCustomerUser`,
          payload,
        ),
    }),
    enableUser: (tenantAlias: string) => ({
      mutationFn: (userID: number) =>
        postSocketRequestPromise<void, EnableUserPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/${userID}/enableUser`,
        ),
    }),
    demoteUserFromAdmin: (tenantAlias: string) => ({
      mutationFn: (userID: number) =>
        postSocketRequestPromise<void, DemoteFromAdminPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/${userID}/demoteFromAdmin`,
        ),
    }),
    promoteUserToAdmin: (tenantAlias: string) => ({
      mutationFn: (userID: number) =>
        postSocketRequestPromise<void, PromoteToAdminPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/${userID}/promoteToAdmin`,
        ),
    }),
    changePassword: (tenantAlias: string) => ({
      mutationFn: (payload: ChangePasswordPayload) =>
        postRequestPromise<void, ChangePasswordPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/changePassword`,
          payload,
        ),
    }),
    uploadProfileImage: (tenantAlias: string, userID: number) => ({
      mutationFn: (payload: UserUploadProfileImagePayload) =>
        uploadRequestPromise<void, UserUploadProfileImagePayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/${userID}/uploadProfileImage`,
          payload,
        ),
    }),
    updateUser: (tenantAlias: string, userID: number) => ({
      mutationFn: (payload: UserUpdateUserPayload) =>
        postRequestPromise<UserUpdateUserResponse, UserUpdateUserPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/user/${userID}`,
          payload,
        ),
    }),
    settings: {
      notificationsData: (tenantAlias: string) => ({
        queryKey: ['user', 'settings', 'notificationsData'],
        queryFn: () =>
          getRequestPromise<SettingsNotificationsDataResponse>(
            `${getSPAApiURLForTenant(
              tenantAlias,
              getHostFromWindow(),
            )}/user/settings/notificationsData`,
          ),
      }),
      updateMuteSMS: (tenantAlias: string) => ({
        mutationFn: (payload: UpdateMuteSMSRequest) =>
          postRequestPromise<void, UpdateMuteSMSRequest>(
            `${getSPAApiURLForTenant(
              tenantAlias,
              getHostFromWindow(),
            )}/user/settings/updateMuteSMS`,
            payload,
            'Unable to modify notification settings at this time. Please try again later',
          ),
      }),
    },
  },
  userInviteToken: {
    queryKey: ['userInviteToken'],
    findOne: (tenantAlias: string, token: string) => ({
      queryKey: ['userInviteToken', 'findOne', token],
      queryFn: () =>
        getRequestPromise<UserInviteTokenFindOneUserInviteTokenResponse>(
          `${getRestApiURLforTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/userInviteToken/${token}`,
        ),
    }),
  },
  watchObject: {
    queryKey: ['watchObject'],
    setEnabled: (
      objectType: string,
      objectID: number,
      tenantAlias: string,
    ) => ({
      mutationFn: (payload: { enabled: boolean }) =>
        postRequestPromise<void, { enabled: boolean }>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/watchObject/${objectType}/${objectID}/setEnabled`,
          payload,
        ),
    }),
  },
  supplierManufacturerInfo: {
    queryKey: ['supplierManufacturerInfo'],
    updateSupplierManufacturerInfo: (tenantAlias: string) => ({
      mutationFn: (payload: UpsertSupplierManufacturerInfoPayload) =>
        postRequestPromise<void, UpsertSupplierManufacturerInfoPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierManufacturerInfo`,
          payload,
        ),
    }),
    getSupplierManufacturerInfo: (
      tenantAlias: string,
      queryParams: GetSupplierManufacturerInfoByManufacturerPayload,
    ) => ({
      queryKey: [
        'supplierManufacturerInfo',
        'getSupplierManufacturerInfo',
        queryParams,
      ],
      queryFn: () =>
        getRequestPromise<GetSupplierManufacturerByManufacturerInfoResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/supplierManufacturerInfo/getByManufacturer`,
          { params: queryParams },
        ),
    }),
  },
  spPartPageSetting: {
    queryKey: ['spPartPageSetting'],
    upsertGlobalSPPartPageSetting: (tenantAlias: string) => ({
      mutationFn: (payload: UpsertGlobalSPPartPageSettingPayload) =>
        postRequestPromise<void, UpsertGlobalSPPartPageSettingPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/spPartPageSetting/upsertGlobalSPPartPageSetting`,
          payload,
        ),
    }),
    upsertSPPartPageSettingByManufacturer: (tenantAlias: string) => ({
      mutationFn: (payload: UpsertSPPartPageSettingByManufacturerPayload) =>
        postRequestPromise<void, UpsertSPPartPageSettingByManufacturerPayload>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/spPartPageSetting/upsertSPPartPageSettingByManufacturer`,
          payload,
        ),
    }),
    getSPPartPageSettingByManufacturer: (
      tenantAlias: string,
      manufacturerID: number,
    ) => ({
      queryKey: [
        'spPartPageSetting',
        'getSPPartPageSettingByManufacturer',
        manufacturerID,
      ],
      queryFn: () =>
        getRequestPromise<GetSPPartPageSettingByManufacturerResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/spPartPageSetting/getSPPartPageSettingByManufacturer`,
          {
            params: { manufacturer: manufacturerID },
          },
        ),
    }),
    getGlobalSPPartPageSetting: (tenantAlias: string) => ({
      queryKey: ['spPartPageSetting', 'getGlobalSPPartPageSetting'],
      queryFn: () =>
        getRequestPromise<GetGlobalSPPartPageSettingResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/spPartPageSetting/getGlobalSPPartPageSetting`,
        ),
    }),
  },
  crm: {
    queryKey: ['crm'],
    getUserHeader: (tenantAlias: string, params: CRMGetUserHeaderPayload) => ({
      queryKey: ['crm', 'getUserHeader', params],
      queryFn: () =>
        getRequestPromise<CRMGetUserHeaderResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/userHeader`,
          { params },
        ),
    }),
    getUserStatistics: (
      tenantAlias: string,
      params: CRMGetUserStatisticsPayload,
    ) => ({
      queryKey: ['crm', 'getUserStatistics', params],
      queryFn: () =>
        getRequestPromise<CRMGetUserStatisticsResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/userStatistics`,
          { params },
        ),
    }),
    getUserActivity: (
      tenantAlias: string,
      params: CRMGetUserActivityPayload,
    ) => ({
      queryKey: ['crm', 'getUserActivity', params],
      queryFn: () =>
        getRequestPromise<CRMGetUserActivityResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/userActivity`,
          { params },
        ),
    }),
    getUserQuotes: (tenantAlias: string, params: CRMGetUserQuotesPayload) => ({
      queryKey: ['crm', 'getUserQuotes', params],
      queryFn: () =>
        getRequestPromise<CRMGetUserQuotesResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/userQuotes`,
          { params },
        ),
    }),
    getUserOrders: (tenantAlias: string, params: CRMGetUserOrdersPayload) => ({
      queryKey: ['crm', 'getUserOrders', params],
      queryFn: () =>
        getRequestPromise<CRMGetUserOrdersResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/userOrders`,
          { params },
        ),
    }),
    getCompanyList: (
      tenantAlias: string,
      params?: CRMGetCompanyListPayload,
    ) => ({
      queryKey: ['crm', 'getCompanyList', params],
      queryFn: () =>
        getRequestPromise<CRMGetCompanyListResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/companyList`,
          { params },
        ),
    }),
    getCompanyHeader: (
      tenantAlias: string,
      params: CRMGetCompanyHeaderPayload,
    ) => ({
      queryKey: ['crm', 'getCompanyHeader', params],
      queryFn: () =>
        getRequestPromise<CRMGetCompanyHeaderResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/companyHeader`,
          { params },
        ),
    }),
    getCompanyStatistics: (
      tenantAlias: string,
      params: CRMCompanyStatisticsPayload,
    ) => ({
      queryKey: ['crm', 'getCompanyStatistics', params],
      queryFn: () =>
        getRequestPromise<CRMCompanyStatisticsResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/companyStatistics`,
          { params },
        ),
    }),
    getCompanyActivity: (
      tenantAlias: string,
      params?: CRMGetCompanyActivityPayload,
    ) => ({
      queryKey: ['crm', 'getCompanyActivity', params],
      queryFn: () =>
        getRequestPromise<CRMGetCompanyActivityResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/companyActivity`,
          { params },
        ),
    }),
    getCompanyQuotes: (
      tenantAlias: string,
      params: CRMGetCompanyQuotesPayload,
    ) => ({
      queryKey: ['crm', 'getCompanyQuotes', params],
      queryFn: () =>
        getRequestPromise<CRMGetCompanyQuotesResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/companyQuotes`,
          { params },
        ),
    }),
    getCompanyOrders: (
      tenantAlias: string,
      params: CRMGetCompanyOrdersPayload,
    ) => ({
      queryKey: ['crm', 'getCompanyOrders', params],
      queryFn: () =>
        getRequestPromise<CRMGetCompanyOrdersResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/companyOrders`,
          { params },
        ),
    }),
    getCompanyDetails: (
      tenantAlias: string,
      params: CRMGetCompanyDetailsPayload,
    ) => ({
      queryKey: ['crm', 'getCompanyDetails', params],
      queryFn: () =>
        getRequestPromise<CRMGetCompanyDetailsResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/companyDetails`,
          { params },
        ),
    }),
    getContactHeader: (
      tenantAlias: string,
      params: CRMGetContactHeaderPayload,
    ) => ({
      queryKey: ['crm', 'getContactHeader', params],
      queryFn: () =>
        getRequestPromise<CRMGetContactHeaderResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/contactHeader`,
          { params },
        ),
    }),
    getContactStatistics: (
      tenantAlias: string,
      params: CRMGetContactStatisticsPayload,
    ) => ({
      queryKey: ['crm', 'getContactStatistics', params],
      queryFn: () =>
        getRequestPromise<CRMGetContactStatisticsResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/contactStatistics`,
          { params },
        ),
    }),
    getContactActivity: (
      tenantAlias: string,
      params: CRMGetContactActivityPayload,
    ) => ({
      queryKey: ['crm', 'getContactActivity', params],
      queryFn: () =>
        getRequestPromise<CRMGetContactActivityResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/contactActivity`,
          { params },
        ),
    }),
    getContactQuotes: (
      tenantAlias: string,
      params: CRMGetContactQuotesPayload,
    ) => ({
      queryKey: ['crm', 'getContactQuotes', params],
      queryFn: () =>
        getRequestPromise<CRMGetContactQuotesResponse>(
          `${getSPAApiURLForTenant(
            tenantAlias,
            getHostFromWindow(),
          )}/crm/contactQuotes`,
          { params },
        ),
    }),
  },
};
