'use client';

import { useState, useCallback } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Box, Drawer, IconButton, Stack } from '@mui/material';
import { LinkedLogoAsSvg } from '../../Logo/LinkedLogoAsSvg';
import salesPilotLogoLarge from './assets/salesPilotLogoLarge.svg';
import { SidebarAuthNav } from './components/SidebarAuthNav';
export const SidebarNav = dynamic(() => import('./components/SidebarNav').then(mod => mod.SidebarNav));
export const SidebarMobileContainer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleSetClosed = useCallback(() => setIsOpen(false), []);
  return <>
      <IconButton sx={{
      display: ['flex', 'flex', 'none'],
      color: 'common.white'
    }} onClick={() => setIsOpen(true)} title="Open Menu" aria-label="Open Menu" data-sentry-element="IconButton" data-sentry-source-file="SidebarMobileContainer.tsx">
        <MenuIcon data-sentry-element="MenuIcon" data-sentry-source-file="SidebarMobileContainer.tsx" />
      </IconButton>
      <Drawer open={isOpen} slotProps={{
      paper: {
        sx: {
          width: '100%',
          maxWidth: 300,
          bgcolor: 'tertiary.dark'
        }
      }
    }} data-sentry-element="Drawer" data-sentry-source-file="SidebarMobileContainer.tsx">
        <Stack direction="row" sx={{
        justifyContent: 'space-between',
        p: 1
      }} data-sentry-element="Stack" data-sentry-source-file="SidebarMobileContainer.tsx">
          <Box sx={{
          px: 1,
          py: 0.5,
          mx: 'auto'
        }} data-sentry-element="Box" data-sentry-source-file="SidebarMobileContainer.tsx">
            <LinkedLogoAsSvg height={[40, 40, 45]} variant="white" data-sentry-element="LinkedLogoAsSvg" data-sentry-source-file="SidebarMobileContainer.tsx" />
          </Box>
          <IconButton title="Close Menu" aria-label="Close Menu" sx={{
          display: ['flex', 'flex', 'none'],
          color: 'tertiary.contrastText'
        }} onClick={handleSetClosed} data-sentry-element="IconButton" data-sentry-source-file="SidebarMobileContainer.tsx">
            <MenuOpenIcon data-sentry-element="MenuOpenIcon" data-sentry-source-file="SidebarMobileContainer.tsx" />
          </IconButton>
        </Stack>
        <SidebarNav onClick={handleSetClosed} data-sentry-element="SidebarNav" data-sentry-source-file="SidebarMobileContainer.tsx" />
        <SidebarAuthNav onClick={handleSetClosed} data-sentry-element="SidebarAuthNav" data-sentry-source-file="SidebarMobileContainer.tsx" />
        <Box sx={{
        m: 2
      }} data-sentry-element="Box" data-sentry-source-file="SidebarMobileContainer.tsx">
          <Image src={salesPilotLogoLarge} alt="Sales Pilot Logo" width={150} height={40} data-sentry-element="Image" data-sentry-source-file="SidebarMobileContainer.tsx" />
        </Box>
      </Drawer>
    </>;
};