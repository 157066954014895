const getHostName = (host: string | null) => {
  /**
   * Remove the port in case of localhost
   * @example `domain-a`, `powermatic.net`, `domain-a.localhost`
   */
  return (host || '').replace(/:\d+/g, '');
};

const AGENT_SUBDOMAIN_PREFIX = 'agent';
const WWW_SUBDOMAIN_PREFIX = 'www';
/**
 * Cases:
 * 1. SPA
 * 1.1 dev: agenttenantalias.localhost.com
 * 1.2 staging: agent.tenantalias.salespilotsites-staging.com
 * 1.3 prod: agent.tenantalias.com
 * 2. SPS
 * 2.1 dev/non-released prod: tenantalias.localhost.com
 * 2.2 prod: tenantalias.com
 * 2.3 prod: www.tenantalias.com
 * Problem: This function needs to be improved to handle TLD such as .co.uk
 * It can only handle .com, .net or other single period TLDs
 */
export const getTenantAliasFromHost = (host: string | null): string => {
  if (!host) return '';
  const hostname = getHostName(host);
  if (!hostname) return '';
  const hostnameParts = hostname.split('.');
  if (hostnameParts.length === 2) {
    // Case 2.2
    return hostnameParts[0];
  } else if (hostnameParts.length === 3) {
    if (hostnameParts[0] === AGENT_SUBDOMAIN_PREFIX) {
      // Case 1.3
      return hostnameParts[1];
    } else if (hostnameParts[0] === WWW_SUBDOMAIN_PREFIX) {
      // Case 2.3
      return hostnameParts[1];
    } else {
      if (hostnameParts[0].startsWith(AGENT_SUBDOMAIN_PREFIX)) {
        // Case 1.1
        return hostnameParts[0].substring(AGENT_SUBDOMAIN_PREFIX.length);
      } else {
        // Case 2.1
        return hostnameParts[0];
      }
    }
  } else if (hostnameParts.length === 4) {
    if (hostnameParts[0] === AGENT_SUBDOMAIN_PREFIX) {
      // Case 1.2
      return hostnameParts[1];
    }
  }
  throw new Error(
    `getTenantAliasFromHost: Unsupported hostname: ${hostname} for host: ${host}`,
  );
};
